import React from 'react';

const OutboundUsage = () => {
    return (
        <div>
            <h2>Outbound Usage</h2>
            {/* Add your outbound usage details here */}
        </div>
    );
};

export default OutboundUsage;
