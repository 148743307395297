import React from 'react';

const InboundUsage = () => {
    return (
        <div>
            <h2>Inbound Usage</h2>
            {/* Add your inbound usage details here */}
        </div>
    );
};

export default InboundUsage;
